import ButtonNeoGen from "../../../layout/button-neogen";
import ModalDialog from "../../../layout/modal-dialog";
import { useForm } from "../../../hooks/useForm";
import { Form } from "../../../layout/form/form";
import { SelectField } from "../../../layout/form/select-field";
import { z } from "zod";
import { User } from "../../../jason-proof-of-concept/users/domain/user";
import { useMemo } from "react";
import { CompanyStatus } from "../../domain/company";
import { DateTimeField } from "../../../layout/form/date-field";

const schema = z.object({
    affiliateIds: z.array(z.string().uuid()).nullish(),
    ownedByIds: z.array(z.string().uuid()).nullish(),
    statuses: z.array(z.string()).nullish(),
    createdAtFrom: z.date().nullish(),
    createdAtTo: z.date().nullish(),
    updatedAtFrom: z.date().nullish(),
    updatedAtTo: z.date().nullish(),
    stageIds: z.array(z.string()).nullish(),
});

export type CompanyFilters = z.infer<typeof schema>;

const companyStatusOptions = [
    {
        label: "In review",
        value: CompanyStatus.inReview,
    },
    {
        label: "Active",
        value: CompanyStatus.active,
    },
    {
        label: "Archived",
        value: CompanyStatus.archived,
    },
];

const stagesOptions = [
    {
        label: "Doc Collection Complete",
        value: "Doc Collection Complete",
    },
];

const nullValues: Required<CompanyFilters> = {
    affiliateIds: null,
    ownedByIds: null,
    statuses: null,
    createdAtFrom: null,
    createdAtTo: null,
    updatedAtFrom: null,
    updatedAtTo: null,
    stageIds: null,
};

export const CompanyFiltersModal = ({
    onClose,
    companyFilters,
    affiliates,
    companyOwners,
    handleSubmit,
}: {
    onClose: () => void;
    companyFilters: CompanyFilters;
    affiliates?: User[];
    companyOwners?: User[];
    handleSubmit: (filters: CompanyFilters) => void;
}) => {
    const form = useForm({ schema, defaultValues: { ...companyFilters } });
    const submitFilters = (data: any) => {
        handleSubmit(data);
    };

    const affiliateOptions = useMemo(
        () =>
            (affiliates || []).map((a) => {
                const email = a.email ? ` (${a.email})` : "";
                return { value: a.id, label: `${a.firstName} ${a.lastName}${email}` };
            }),
        [affiliates],
    );

    const companyOwnersOptions = useMemo(
        () =>
            (companyOwners || []).map((a) => {
                const email = a.email ? ` (${a.email})` : "";
                return { value: a.id, label: `${a.firstName} ${a.lastName}${email}` };
            }),
        [companyOwners],
    );

    const createdAtFrom = form.watch("createdAtFrom");
    const createdAtTo = form.watch("createdAtTo");
    const updatedAtFrom = form.watch("updatedAtFrom");
    const updatedAtTo = form.watch("updatedAtTo");
    const now = useMemo(() => new Date(), []);

    return (
        <ModalDialog show title="Filters" close={onClose} showOk={false} showCancel={false} size="sm">
            <Form onSubmit={form.handleSubmit(submitFilters)}>
                <SelectField
                    isMultiple
                    label="Affiliate"
                    {...form.getFieldProps("affiliateIds")}
                    options={affiliateOptions}
                />
                <SelectField
                    isMultiple
                    label="Company status"
                    {...form.getFieldProps("statuses")}
                    options={companyStatusOptions}
                />
                <SelectField
                    isMultiple
                    label="Company owner"
                    {...form.getFieldProps("ownedByIds")}
                    options={companyOwnersOptions}
                />
                <div className="flex justify-between items-center gap-4">
                    <div className="flex-1">
                        <DateTimeField
                            label="Created from"
                            {...form.getFieldProps("createdAtFrom")}
                            showTimeInput={false}
                            showTimeSelect={false}
                            maxDate={createdAtTo || now}
                            isClearable
                        />
                    </div>
                    <div className="flex-1">
                        <DateTimeField
                            label="Created to"
                            {...form.getFieldProps("createdAtTo")}
                            showTimeInput={false}
                            showTimeSelect={false}
                            maxDate={now}
                            minDate={createdAtFrom}
                            isClearable
                        />
                    </div>
                </div>
                <div className="flex justify-between items-center gap-4">
                    <div className="flex-1">
                        <DateTimeField
                            label="Updated from"
                            {...form.getFieldProps("updatedAtFrom")}
                            showTimeInput={false}
                            showTimeSelect={false}
                            maxDate={updatedAtTo || now}
                            isClearable
                        />
                    </div>
                    <div className="flex-1">
                        <DateTimeField
                            label="Updated to"
                            {...form.getFieldProps("updatedAtTo")}
                            showTimeInput={false}
                            showTimeSelect={false}
                            maxDate={now}
                            minDate={updatedAtFrom}
                            isClearable
                        />
                    </div>
                </div>

                <SelectField isMultiple label="Stages" {...form.getFieldProps("stageIds")} options={stagesOptions} />
                <div className="flex justify-between">
                    <ButtonNeoGen type="submit">Apply filters</ButtonNeoGen>
                    <ButtonNeoGen
                        type="outline"
                        onClick={() => {
                            form.reset(nullValues);
                        }}
                    >
                        Reset filters
                    </ButtonNeoGen>
                </div>
            </Form>
        </ModalDialog>
    );
};
